import { useEffect, useMemo, useState } from 'react';
import { DOTS } from './constants';
import { IUsePagination } from './interfaces';
import jwtDecode from 'jwt-decode';

export const checkToken = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace('jwt=', ''));

  if (jwtValue && (parsedToken.role === 'ADMIN' || parsedToken.role === 'SUPER_ADMIN')) {
    return true;
  }

  return false;
};

const range = (start: number, end: number): number[] => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount,
  currentPage,
  totalPageCount,
}: IUsePagination) => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage, totalPageCount]);

  return paginationRange;
};

export function formatPrice(value: any) {
  if (value == null || value === undefined || !Number(value)) return '-';

  return Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);
}

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

export const transformPhoneNumber = (recipientPhone: string): string => {
  if (recipientPhone) {
    const LOCAL_PHONE_NUMBER_LENGTH = 10;
    const MAX_PHONE_NUMBER_LENGTH = 11;
    let globalPhone = recipientPhone;
    let phoneLength = globalPhone.length;
    let localPhone = globalPhone.substring(phoneLength - LOCAL_PHONE_NUMBER_LENGTH, phoneLength);
    let countryCode = '1';

    if (phoneLength > MAX_PHONE_NUMBER_LENGTH || phoneLength < LOCAL_PHONE_NUMBER_LENGTH) {
      return recipientPhone;
    }

    if (phoneLength > LOCAL_PHONE_NUMBER_LENGTH) {
      countryCode = globalPhone.substring(0, phoneLength - LOCAL_PHONE_NUMBER_LENGTH);
    }

    return `+${countryCode}(${localPhone.substring(0, 3)})-${localPhone.substring(
      3,
      6,
    )}-${localPhone.substring(6, LOCAL_PHONE_NUMBER_LENGTH)}`;
  } else {
    return '';
  }
};

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export const parseCartDetails = (cartData: any) => {
  try {
    return JSON.parse(cartData?.cartDetails?.cartJsonData || '{}');
  } catch (error) {
    console.error('Error parsing cartJsonData:', error);
    return {};
  }
};

export const safeParseNestedJson = (value: any) => {
  try {
    while (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value;
  } catch {
    return value;
  }
}
