import moment from 'moment';
import React, { useRef } from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { safeParseNestedJson } from '../../utils/services';

import './fieldChangesPopover.scss';


interface FieldChangesPopoverProps {
  updated: boolean;
  updatedBy: string;
  newValue: string;
  oldValue: string;
  updatedAt: string;
}

const FieldChangesPopover: React.FC<FieldChangesPopoverProps> = ({
  updated = false,
  updatedBy = 'unknown',
  newValue = '',
  oldValue = '',
  updatedAt = '',
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const buttonRef = useRef(null);
  
  const showPopover = () => {
    setPopoverOpen(true);
  };

  const hidePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <button
        style={{ border: '0', background: 'none', padding: '0', cursor: 'pointer' }}
        ref={buttonRef}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
      >
        <i className="fa fa-solid fa-circle-info ml-2" style={{ color: updated ? '#ffcc00' : '#2dce89'}}></i>
      </button>

      <UncontrolledPopover
        isOpen={popoverOpen}
        style={{ maxWidth: '30rem' }}
        placement="right"
        target={buttonRef}
      >
        <PopoverBody>
          {updated && (
            <div>
              <p>
                <strong>User:</strong> {updatedBy}
              </p>
              <p>
                <strong>New Value:</strong> <pre className="pre-popover-value">{newValue}</pre>
              </p>
              <p>
                <strong>Old Value:</strong> <pre className="pre-popover-value">{oldValue}</pre>
              </p>
              <p>
                <strong>Updated At:</strong> {updatedAt && moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')}
              </p>
            </div>
          )}
          {!updated && <span>No changes available</span>}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export const renderFieldChanges = (config: Record<string, any>, logs: any, index = 0) => {
  const data = logs ? JSON.parse(logs) : null;
  
  if (!config?.isShowChanges && !config?.cartInfo && !(Array.isArray(data?.new_value) || !Array.isArray(data?.old_value))) return null;

  let updatedBy = '';
  let newValue = '';
  let oldValue = '';
  let updatedAt = '';

  if (data && (data?.new_value || data?.old_value) && (Array.isArray(JSON.parse(data.new_value)) || Array.isArray(JSON.parse(data.old_value)))) {
    newValue = JSON.parse(data?.new_value).map((item: any)=> {
      const arr = [];
      for (const [key, value] of Object.entries(item)) {
        arr.push(`${key}: ${value}`);
      }
      return arr.join('\n');
    }).join('\n');
    
    oldValue = JSON.parse(data?.old_value).map((item: any)=> {
      const arr = [];
      for (const [key, value] of Object.entries(item)) {
        arr.push(`${key}: ${value}`);
      }
      return arr.join('\n');
    }).join('\n');
    
    updatedAt = data ? data?.updated_at : '-';
    updatedBy = data ? data?.updated_by : 'Unknown';
  } else if (data?.transfer1 || data?.transfer2) {
    newValue = data ? data?.transfer1?.[config.name]?.new_value || data?.transfer2?.[config.name]?.new_value || '-' : '-';
    oldValue = data ? data?.transfer1?.[config.name]?.old_value || data?.transfer2?.[config.name]?.old_value || '-' : '-';
    updatedAt = data ? data?.transfer1?.[config.name]?.updated_at || data?.transfer2?.[config.name]?.updated_at || '-' : '-';
    updatedBy = data ? data?.transfer1?.[config.name]?.updated_by || data?.transfer2?.[config.name]?.updated_by || '-' : 'Unknown';
  } else {
    if (data && config?.vendorPhone) {
      newValue = data ? data?.vendorPhone?.new_value : '-';
      oldValue = data ? data?.vendorPhone?.old_value : '-';
      updatedAt = data ? data?.vendorPhone?.updated_at : '-';
      updatedBy = data ? data?.vendorPhone?.updated_by : 'Unknown';
    } else if (data && config.name === 'attendeeName') {
      const newValueArr = safeParseNestedJson(data?.['attendeeNames']?.new_value);
      const oldValueArr = safeParseNestedJson(data?.['attendeeNames']?.old_value);

      newValue = Array.isArray(newValueArr)
        ? newValueArr.map((item: any) => item.attendeeName).join('\n')
        : '-';
      oldValue = Array.isArray(oldValueArr)
        ? oldValueArr.map((item: any) => item.attendeeName).join('\n')
        : '-';

      updatedAt = data?.['attendeeNames']?.updated_at || '-';
      updatedBy = data?.['attendeeNames']?.updated_by || 'Unknown';
    } else {
      newValue = data ? data?.[config.name]?.new_value : '-';
      oldValue = data ? data?.[config.name]?.old_value : '-';
      updatedAt = data ? data?.[config.name]?.updated_at : '-';
      updatedBy = data ? data?.[config.name]?.updated_by : 'Unknown';
    }
  }

  return (
    <FieldChangesPopover
      updated={newValue !== oldValue && oldValue !== 'unknown'}
      updatedBy={updatedBy}
      newValue={newValue}
      oldValue={oldValue}
      updatedAt={updatedAt}
    />
  );
};